import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "./layout"
import AlpFoto from "../images/alp.jpg"
import downloadFile from "../downloads/cv.pdf"

const About = ({ location, i18n }, props) => {
  return (
    <Layout location={location}>
      <div>
        <div className="about__container">
          <img
            src={AlpFoto}
            alt="alperen karlı foto"
            className="uk-border-circle about__image"
            width="200"
            height="200"
          />
          {i18n.description}
        </div>
        <div className="summary__container">
          <a href={downloadFile} download={"alperen_karli_cv"}>
            <button className="uk-button uk-button-primary flex-container flex-button">
              <span>{i18n.cv}</span>
              <span data-uk-icon="icon: download; ratio: 1"></span>
            </button>
          </a>
          <Link to={"/works"}>
            <button className="uk-button uk-button-secondary">
              {i18n.works}
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default About

About.propTypes = {
  location: PropTypes.object.isRequired,
}
