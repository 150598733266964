import React from "react"
import About from "../../components/about"

const i18n = {
  cv: 'CV',
  description: (
    <p>
      Hi! I am Alperen.
      <br />I don't want to talk about who I am.
      <br />If you get lost on the website, you know me better.
      <br /><br />Follow the white rabbit.
    </p>
  ),
  works: 'Works'
}

export default props => <About i18n={i18n} {...props} />
